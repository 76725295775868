<template>
  <div id="vm-cola-products" class="vm-cola-products">
    <div class="vm-cola-products__header">
      <div class="vm-cola-products__header--left">
        <div class="back" @click="showPopupBackHome">
          <img src="/static/img/icons/icon-left-arrow.svg" />
        </div>

        <HeaderLogo />
      </div>
    </div>

    <div ref="product-list-wrapper" class="product-list-wrapper" @scroll="handleScroll">
      <ProductShoppingBagComponent
        v-if="show_cart && cart_qty_total > 0"
        :products="selected_product_list"
        @height="(h) => (shoppingBagHeight = h)"
        @minus-cart="(item) => handleMinusCart(item)"
        @plus-cart="(item) => handlePlusCart(item)"
        @remove-cart="(item) => handleRemoveProduct(item)"
      />

      <p v-if="show_cart && cart_qty_total > 0 && cart_qty_total < 4" class="remaining_cart">
        Kamu masih bisa menambahkan {{ 4 - cart_qty_total }} produk, tambah beauties lainnya?
      </p>
      <template v-if="!loading_product && product_list && product_list.length">
        <div v-for="loop in number_of_loops" :key="loop" class="product-list">
          <template v-if="product_list.length">
            <div v-for="(item, index) in product_list" :key="index" class="product-wrapper">
              <DisplayProduct :product="item">
                <div slot="cart-action" class="cart-action">
                  <template v-if="item.cart_action === 'EMPTY'">
                    <div
                      class="cart-action__add"
                      :class="{ disabled: item.vending_stock <= 0 }"
                      @click.stop.prevent="handleCalculateCart(item, 1, 'initiate')"
                    >
                      <template v-if="item.vending_stock > 0">
                        <img src="/static/img/icons/cart-action__add.svg" />
                        <span class="cart-action__title">Masukkan keranjang</span>
                      </template>
                      <template v-else> Stok habis </template>
                    </div>
                  </template>
                  <template v-else-if="item.cart_action === 'EXIST'">
                    <div class="calculate">
                      <img
                        src="/static/img/icons/icon-minus-borderless-pink.svg"
                        @click.stop.prevent="handleMinusCart(item)"
                      />
                      <span @click.stop.prevent>
                        {{ item.quantities }}
                      </span>
                      <img
                        src="/static/img/icons/icon-plus-borderless-pink.svg"
                        @click.stop.prevent="handlePlusCart(item)"
                      />
                    </div>
                  </template>
                </div>
              </DisplayProduct>
            </div>
            <div class="product-list-ending-separator">
              <div id="stars">
                <img src="/static/img/vm-cola/product-grid-separator.svg" />
              </div>
              <img id="separator" src="/static/img/vm-cola/product-grid-line.svg" />
              <img id="separator2" src="/static/img/vm-cola/product-grid-line.svg" />
            </div>
          </template>
          <template v-if="loading_loadmore_product">
            <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
          </template>
        </div>
      </template>
      <div v-else-if="loading_product" class="product-list">
        <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
      </div>
    </div>

    <ModalAddProduct
      v-if="modal_add_product"
      @continue-shopping="modal_add_product = false"
      @show-cart="() => handleShowCart()"
    />
    <ModalConfirmRemoveProduct
      v-if="modal_remove_product"
      @close="modal_remove_product = false"
      @delete-product="removeProduct()"
    />
    <ModalConfirmCart v-if="modal_confirm" :total-qty="cart_qty_total" @close="modal_confirm = false" />
    <ModalConfirmExit v-if="modal_exit" :total-qty="cart_qty_total" @close="modal_exit = false" @confirm="goBackHome" />
    <ModalExceedMaxProducts v-if="modal_exceed_max_products" @close="modal_exceed_max_products = false" />
    <ModalConfirmRenewShoppingBag
      v-if="modal_reset_shopping_cart"
      :cart-qty="cart_qty_total"
      @close="modal_reset_shopping_cart = false"
      @confirm="resetCart(qty_reset)"
    />

    <div v-if="show_back_to_top" class="back-to-top">
      <img src="/static/img/vm-cola/back-to-top.svg" @click="backToTop" />
    </div>

    <FooterCart
      :cart-showed="show_cart"
      @reset="handleReset"
      @show-cart="() => handleShowCart()"
      @checkout="() => goToOrder()"
    />
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import autotimeout from '@/mixins/auto-timeout';

export default {
  name: 'vmColaProducts',
  components: {
    DisplayProduct: () => import('@/components/vm-cola/snippets/display-product/display-product'),
    // HomeBanners: () => import('@/components/vm-cola/snippets/home-banners/home-banners'),
    ModalConfirmCart: () => import('@/components/vm-cola/snippets/modal/modal-confirm-cart'),
    ModalConfirmExit: () => import('@/components/vm-cola/snippets/modal/modal-confirm-exit'),
    ModalAddProduct: () => import('@/components/vm-cola/snippets/modal/modal-add-product'),
    FooterCart: () => import('@/components/vm-cola/snippets/footer/cart'),
    ModalExceedMaxProducts: () => import('@/components/vm-cola/snippets/modal/modal-exceed-max-products'),
    ModalConfirmRemoveProduct: () => import('@/components/vm-cola/snippets/modal/modal-confirm-remove-product'),
    ModalConfirmRenewShoppingBag: () => import('@/components/vm-cola/snippets/modal/modal-confirm-renew-shopping-bag'),
    ProductShoppingBagComponent: () => import('./shopping-bag'),
    HeaderLogo: () => import('@/components/vm-cola/snippets/header/header-logo.vue'),
  },
  mixins: [helpers, autotimeout],
  beforeRouteLeave(to, from, next) {
    if (to.name == 'vmColaHome') {
      this.cancelOrder();
    }
    next();
  },

  data() {
    return {
      modal_add_product: false,
      modal_remove_product: false,
      modal_confirm: false,
      modal_exit: false,
      modal_exceed_max_products: false,
      modal_reset_shopping_cart: false,

      show_cart: false,
      shoppingBagHeight: 0,

      product_scroll: null,
      show_back_to_top: false,

      product_remove: null,

      qty_reset: 0,
      number_of_loops: 1,
    };
  },

  computed: {
    product_list() {
      return this.$store.state.vmCola.product_list;
    },
    selected_product_list() {
      if (this.carts?.length) {
        return this.carts;
      }

      return this.product_list
        .filter((p) => p.quantities > 0)
        .map((pr) => {
          return {
            name: pr.name,
            raw: {
              detail: {
                ...pr,
                combination: pr.combination || pr.combinations[0],
              },
            },
            qty: pr.quantities,
          };
        });
    },
    loading_product() {
      return this.$store.state.vmCola.loading_product;
    },
    loading_loadmore_product() {
      return this.$store.state.vmCola.loading_loadmore_product;
    },
    carts() {
      return this.$store.state.vmCola.footer_cart || [];
    },
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
    total_items() {
      return (this.carts?.length && this.carts.reduce((acc, curr) => (acc += curr.qty), 0)) || 0;
    },
    availableOnCart() {
      const cart = ls.get('cart') || this.carts;

      if (cart) {
        return typeof cart === 'object' ? cart : JSON.parse(cart);
      }
      return {};
    },
    isLoggedIn() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    authToken() {
      return this.$store.state.auth.authToken;
    },
  },

  watch: {
    product_scroll: {
      handler() {
        this.show_back_to_top = this.product_scroll > 0;
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$store.commit('RESET_SKIP');
    this.$store.dispatch('getProductList');
  },
  beforeDestroy() {
    this.$store.commit('RESET_SKIP');
    this.$store.commit('SET_GLOBAL_LOADING', false);
  },
  methods: {
    backToTop() {
      const productListWrapper = this.$refs['product-list-wrapper'];
      productListWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleShowCart() {
      this.show_cart = true;
      this.modal_add_product = false;
      this.backToTop();
    },
    async removeProduct() {
      this.product_remove.toggle = true;

      if (this.product_remove.qty <= 0) {
        return;
      }

      const productQty = this.product_remove.qty;

      for (let i = 0; i < productQty; i++) {
        await this.handleCalculateCart(this.product_remove.raw.detail, -1, 'minus');
      }
      this.product_remove = null;
      this.modal_remove_product = false;
    },
    pickImage(item) {
      let prdImg = item.images && item.images.length && item.images[0];

      if (!prdImg) {
        prdImg = item.image && item.image;
        Object.assign(item, { images: [prdImg] });
      }

      const defaultCombImg =
        item.default_combination && item.default_combination.images && item.default_combination.images.length;
      if (prdImg || defaultCombImg) {
        let imageCover;
        if (prdImg && item.images.findIndex((res) => res.is_cover) > -1) {
          imageCover = item.images.find((res) => res.is_cover).url;
        }
        if (
          defaultCombImg &&
          item.default_combination.images[0] &&
          item.default_combination.images.findIndex((res) => res && 'is_cover' in res && res.is_cover) > -1
        ) {
          imageCover = item.default_combination.images.find((res) => res.is_cover).url;
        }
        if (imageCover) {
          return imageCover;
        }
      }
      return 'https://placehold.it/100x100';
    },

    async resetCart(qty_total) {
      if (qty_total <= 0) {
        return;
      }

      try {
        await Promise.all([
          ls.remove('cart'),
          this.$store.dispatch('resetCart', 0),
          this.$store.commit('footer_cart', []),
          ls.remove('PRODUCT_LIST'),
          this.$store.commit('PRODUCT_LIST', []),
        ]);
      } catch (error) {
        console.log(error);
      }

      this.$store.commit('RESET_SKIP');
      this.$store.dispatch('getProductList');
      this.modal_reset_shopping_cart = false;
    },

    async handleReset(qty_total) {
      this.qty_reset = qty_total;
      this.modal_reset_shopping_cart = true;
    },
    // handleShowCart(qty_total) {
    //   if (qty_total <= 0) {
    //     return;
    //   }
    // },
    async goToOrder() {
      if (this.cart_qty_total === 0) {
        return;
      }
      if (this.isLoggedIn && this.authToken) {
        this.$store.commit('SET_GLOBAL_LOADING', true);

        const [is_guest] = await Promise.all([
          ls.get('is_guest'),
          this.removeProducts(),
          this.$store.commit('SET_ALREADY_SEND_CART', false),
        ]);

        await this.$store
          .dispatch('sendCartData', {
            data: this,
            is_vmcola: true,
            is_guest: is_guest === 'true' || is_guest === true,
          })
          .then((res) => {
            this.$store.commit('SET_GLOBAL_LOADING', false);
            this.$router.push('/vmcola/order');
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit('SET_GLOBAL_LOADING', false);
          });
        return;
      }

      this.$router.push('/vmcola/order/login');
    },
    showPopupBackHome() {
      if (this.cart_qty_total <= 0) {
        this.goBackHome();
      }
      this.modal_exit = true;
    },
    showPopupCheckout() {
      if (this.cart_qty_total <= 0) {
        return;
      }

      // this.modal_confirm = true;
    },
    async goBackHome() {
      await Promise.all([
        ls.remove('cart'),
        this.$store.dispatch('resetCart', 0),
        ls.remove('PRODUCT_LIST'),
        this.$store.commit('PRODUCT_LIST', []),
      ]);
      this.$router.push('/vmcola/home');
    },
    deleteUnuseData(prod) {
      delete prod.review_stats;
      delete prod.categories;
      delete prod.discounts;
      delete prod.bogo_rules;
      delete prod.parent_category;
      delete prod.max_discount;
      return prod;
    },
    handleScroll(e) {
      try {
        const target = e.target;
        const height = target.scrollHeight;
        const content_height = target.offsetHeight;
        const top = target.scrollTop;

        this.product_scroll = top;
        if (height - 5 <= content_height + top) {
          this.$store.dispatch('getProductList', true);
          if (this.number_of_loops <= 15 && this.product_list.length >= 6) {
            this.number_of_loops += 1;
          }
        }
      } catch (err) {}
    },
    setCombinationName(code) {
      let result = '';
      try {
        const split_1 = code.split(',');
        const split_2 = split_1[0].split(':');
        const split_3 = split_1[1].split(':');
        const text_1 = `<b>${split_2[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split_2[1]}`;
        const text_2 = `<b>${split_3[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split_3[1]}`;
        result = `${text_1}<br>${text_2}`;
      } catch (e) {
        const split = code.split(':');
        result = `<b>${split[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split[1]}`;
      }

      return result;
    },

    handleMinusCart(product) {
      product.toggle = true;

      if (product.quantities <= 0) {
        return;
      }

      this.handleCalculateCart(product, -1, 'minus');
    },
    async handleRemoveProduct(product) {
      this.product_remove = product;
      this.modal_remove_product = true;
    },

    handlePlusCart(product) {
      if (this.total_items >= 4) {
        // this.$toasted.global.error('You cannot add more than 4 items.');
        this.modal_exceed_max_products = true;
        return;
      }

      const vending_stock = product?.vending_stock || 0;

      if (vending_stock && product.quantities >= vending_stock) {
        return;
      }

      product.toggle = true;
      this.handleCalculateCart(product, 1);
    },

    async handleCalculateCart(product, qty = 1, action = '') {
      if (product.vending_stock <= 0) {
        return;
      }

      if (this.total_items >= 4 && action !== 'minus') {
        // this.$toasted.global.error('You cannot add more than 4 items.');
        this.modal_exceed_max_products = true;
        return;
      }

      if (action === 'initiate') {
        this.modal_add_product = true;
      }

      product.toggle = true;

      if (this.availableOnCart && Object.keys(this.availableOnCart).length && this.availableOnCart.qty) {
        const qtyTotal = this.availableOnCart.qty + qty;

        if (Number(qtyTotal) > product.stock) {
          this.$toasted.global.error('Produk melebihi batas maksimal.');
          return;
        }
      }

      const product_payload = {
        id: product.id,
        combination_id: product.default_combination['_id'],
        quantity: product.quantities + qty,
        slot: product.vending_slot,
        vending_details: product.vending_details,
      };

      if (product_payload.is_special_price_applicable) {
        (product_payload.is_special_price = true),
          (product_payload.special_price_voucher_id = product_payload.special_price_voucher_id);
      }

      product_payload._id = product._id;
      product_payload.detail = this.deleteUnuseData(product);
      if (product_payload.detail['combinations'] && product_payload.detail['combinations'].length) {
        product_payload.detail['combinations'] = product_payload.detail['combinations'].map((prod) =>
          this.deleteUnuseData(prod)
        );
      }
      if (
        product_payload.detail['default_combination'] &&
        Object.entries(product_payload.detail['default_combination']).length
      ) {
        product_payload.detail['default_combination'] = this.deleteUnuseData(
          product_payload.detail['default_combination']
        );
      }

      const combination__name = [];
      for (const i in product.default_combination.attributes) {
        combination__name.push(`${i}: ${product.default_combination.attributes[i].name}`);
      }

      Promise.all([
        this.$store.dispatch('addToCartNoLogin', {
          product: product_payload,
          combination: product.default_combination,
          combination_name: this.setCombinationName(combination__name.join(', ')),
          with_popup: false,
          operator: Math.sign(qty) > -1 ? 'plus' : 'minus',
        }),
      ]).then(async () => {
        await this.$store.dispatch('recalculateProducts');
        setTimeout(() => {
          product.toggle = false;
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss">
.product-list-ending-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 57px 0;
  width: 100%;
  position: relative;

  #stars {
    min-height: 50px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }

  #separator {
    position: absolute;
    top: 50%;
    height: 1px;
    transform: translateY(-50%);
    left: -10px;
    width: calc(50vw - 150px);
    border: 1px solid #c5bebb;
  }
  #separator2 {
    position: absolute;
    top: 50%;
    height: 1px;
    transform: translateY(-50%);
    right: -10px;
    width: calc(50vw - 150px);
    border: 1px solid #c5bebb;
  }
}

/deep/ .loaderspin {
  border: 10px solid #f083a6;
  border-top: 10px solid #b22655;
  border-right: 10px solid #b22655;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
</style>
